const objToArray = (obj) => {
    let arr = [];
    Object.keys(obj).forEach(key => {
        arr.push({
            name: obj[key],
            id: key
        })
    })
    return arr
}

export default objToArray