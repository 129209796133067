<template>
  <div v-if="isOpen" class="modal fade show modal-css" aria-labelledby="addNewCardTitle">
    <div class="modal-dialog modal-dialog-centered modal-custom">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label">Type</label>
                    <v-select
                        v-model="formData.resource_type"
                        label="name"
                        :options="typeArr"
                        :reduce="name => name.id"
                    />
                  </div>

                  <div class="col-md-12">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                      <label for="colFormLabel" class="col-form-label">Contact Profile: </label>
                      <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect
                        placeholder="Select Party"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :format-label="formatPatientLabel"
                    />
                  </div>

                  <div class="col-md-12">
                    <label class="form-label">Service User: </label>
                    <v-select 
                      placeholder="Select User" class="w-100" 
                      :options="userList" label="name"
                      :reduce="(name) => name.id" 
                      v-model="formData.service_user_id" 
                    />
                  </div>

                  <div class="col-md-12">
                    <label class="form-label" for="chartName">Name</label>
                    <div class="input-group">
                      <vField
                          type="text"
                          name="name"
                          v-model="formData.name"
                          class="form-control add-credit-card-mask"
                          placeholder="Name"
                      />
                      <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                    </div>
                  </div>

                  <div v-if="showParent" class="col-md-12">
                    <label class="form-label">Parent Group</label>
                    <v-select
                        v-model="formData.head_group_id"
                        label="name"
                        :options="group"
                        :reduce="name => name.id"
                    />
                  </div>
                  <div class="col-md-12">
                    <label class="form-label" for="chartName">Service Code</label>
                    <div class="input-group">
                      <vField
                          type="text"
                          name="code"
                          v-model="formData.service_code"
                          class="form-control add-credit-card-mask"
                          placeholder="Code"
                      />
                      <ErrorMessage name="code" class="invalid-feedback d-block qcont"/>
                    </div>
                  </div>


                  <div class="col-md-12">
                    <label class="form-label" for="short_description">Short Description</label>
                    <vField
                        as="textarea"
                        v-model="formData.short_description"
                        name="description"
                        id="short_description"
                        class="form-control add-credit-card-mask"
                        placeholder="Short Description"
                    />
                    <ErrorMessage name="short_description" class="invalid-feedback d-block qcont"/>
                  </div>

                  <div class="col-md-12">
                    <label class="form-label" for="full_description">Full Description</label>
                    <vField
                        as="textarea"
                        v-model="formData.full_description"
                        name="full_description"
                        id="full_description"
                        class="form-control add-credit-card-mask"
                        placeholder="Full Description"
                    />
                    <ErrorMessage name="full_description" class="invalid-feedback d-block qcont"/>
                  </div>
                  <div class="col-md-6">
                      <label class="form-label" for="top_margin">Margin Top(CM)</label>
                      <div class="input-group">
                        <vField
                            type="number"
                            name="top_margin"
                            v-model="formData.top_margin"
                            class="form-control add-credit-card-mask"
                            placeholder="Margin Top (127)"
                        />
                        <ErrorMessage name="top_margin" class="invalid-feedback d-block qcont"/>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <label class="form-label" for="bottom_margin">Margin Bottom(CM)</label>
                      <div class="input-group">
                        <vField
                            type="number"
                            name="bottom_margin"
                            v-model="formData.bottom_margin"
                            class="form-control add-credit-card-mask"
                            placeholder="Margin Bottom (127)"
                        />
                        <ErrorMessage name="bottom_margin" class="invalid-feedback d-block qcont"/>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <label class="form-label" for="left_margin">Margin Left(CM)</label>
                      <div class="input-group">
                        <vField
                            type="number"
                            name="left_margin"
                            v-model="formData.left_margin"
                            class="form-control add-credit-card-mask"
                            placeholder="Margin Left (127)"
                        />
                        <ErrorMessage name="left_margin" class="invalid-feedback d-block qcont"/>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <label class="form-label" for="right_margin">Margin Right(CM)</label>
                      <div class="input-group">
                        <vField
                            type="number"
                            name="right_margin"
                            v-model="formData.right_margin"
                            class="form-control add-credit-card-mask"
                            placeholder="Margin Right (127)"
                        />
                        <ErrorMessage name="right_margin" class="invalid-feedback d-block qcont"/>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="table-responsive min-height-300">
                  <table
                      class="table table-bordered  mt-2"
                      v-if="formData.resource_descriptions?.length > 0"
                  >
                    <thead>
                    <tr>
                      <th>Product/Service</th>
                      <th>Sales price</th>
                      <th>Sales account</th>
                      <th>Cost of sales</th>
                      <th>Cost of sales account</th>
                      <th>Duration</th>
                      <th>Specification</th>
                      <th>Is OPD Billing</th>
                      <th>Weekly</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(description, index) in formData.resource_descriptions"
                        :key="index"
                        class="text-center"
                    >
                      <td class="p-1">
                        <v-select
                            placeholder="Select Product/Service"
                            v-model="description.product_id"
                            label="text"
                            :options="products"
                            :reduce="name => name.id"
                        />
                      </td>
                      <td class="p-1">
                        <input
                            type="text"
                            v-model="description.sales_price"
                            class="form-control add-credit-card-mask text-end"
                            placeholder="sale price"
                        />
                      </td>
                      <td class="p-1">
                        <input type="hidden" name="sales_account_head_id" class="d-none"
                               v-model="description.sales_account_head_id"/>
                        <v-select
                            v-model="description.sales_account_head_id"
                            :options="salesHead"
                            label="name"
                            name="sales_account_head_id"
                            :reduce="name => name.id"
                        />
                      </td>
                      <td class="p-1">
                        <input
                            type="text"
                            v-model="description.cost_of_sales_price"
                            class="form-control add-credit-card-mask text-end"
                            placeholder="Cost of sales"
                        />
                      </td>
                      <td class="p-1">
                        <input type="hidden" name="cost_of_sales_account_head_id" class="d-none"
                               v-model="description.cost_of_sales_account_head_id"/>
                        <v-select
                            v-model="description.cost_of_sales_account_head_id"
                            :options="costHead"
                            label="name"
                            name="cost_of_sales_account_head_id"
                            :reduce="name => name.id"
                        />
                      </td>
                      <td class="p-1">
                        <input type="text" class="form-control" v-model="description.service_duration">
                      </td>
                      <td class="p-1">
                        <div class="col-md-12">
                          <v-select
                              v-model="description.service_specification"
                              label="name"
                              :options="specificationArr"
                              :reduce="name => name.id"
                          />
                        </div>
                      </td>
                      <td class="p-1">
                        <input type="checkbox" v-model="description.is_opd_billing" class="form-check-input">
                      </td>
                      <td class="p-1">
                        <select @change="getOpenSchedules(description)" class="form-control"
                                v-model="description.is_repeat_weekly"
                                name="is_repeat_weekly" id="">
                          <option value="">Select</option>
                          <option value="1">Repeat weekly</option>
                          <option value="0">Does not repeat</option>
                        </select>
                      </td>
                      <td class="d-flex justify-content-center gap-2 p-1">
                        <button type="button" @click="removeDescription(index)" class="btn btn-sm btn-danger">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <button @click="addMoreDescription" class="btn btn-primary btn-sm mt-1" type="button">Add more</button>
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        type="all"
    />
  </div>

</template>

<script setup>
import {computed, ref, watch} from "vue";
import objToArray from '@/services/utils/object-to-array'
import {useStore} from "vuex";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleContact from "@/services/modules/contact";

const store = useStore()

const props = defineProps({
  formData: Object,
  showParent: {
    type: Boolean,
    default: false
  },
  type: Object,
  group: Array,
  contacts: Array,
  userList: Array,
  products: Array,
  accountHeads: Array,
  specifications: Object,
  modalTitle: {
    type: String,
    default: 'Add service resource'
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})
const contactProfile = ref(null)
const { fetchContactProfiles } = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();

const onOpenContactSearchModal = () => {
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  contactProfile.value = contact
}

const typeArr = computed(() => objToArray(props.type))
const specificationArr = computed(() => objToArray(props.specifications))
const $emit = defineEmits(['onSubmit', 'onClose'])


const addMoreDescription = () => {
  props.formData.resource_descriptions.push({
    product_id: '',
    service_resource_id: '',
    sales_account_head_id: '',
    sales_price: 0,
    cost_of_sales_account_head_id: '',
    cost_of_sales_price: 0,
    service_specification: '',
    service_duration: '',
    is_repeat_weekly: 1,
    schedules: '',
  })
}

const salesHead = computed(() => props.accountHeads.filter(i => i.account_slug == 'sales_accounts'))
const costHead = computed(() => props.accountHeads.filter(i => i.account_slug == 'cost_of_sales'))

const removeDescription = (index) => {
  props.formData.resource_descriptions.splice(index, 1)
}

let isOpen = ref(false)
const loading = ref(false)
const schema = ref({})

const toggleModal = (isEdit = false) => {
  isOpen.value = !isOpen.value
  if (!isEdit) {
    $emit('onClose')
  }
}

const submit = () => {
  if (contactProfile.value) {
    props.formData.contact_profile_id = contactProfile.value.id
  }
  $emit("onSubmit")
}
const getOpenSchedules = (description) => {
  console.log('Working on')
}
defineExpose({toggleModal, loading})
</script>
<style scoped>
.modal-css {
  display: block;
  background: #1c1d1c33
}

.modal-custom {
  max-width: 70vw;
}

.min-height-300 {
  min-height: 300px;
}
</style>
