<template>
  <div class="card p-2" style="min-height: calc(100vh - 185px);">
    <div class="d-flex flex-wrap justify-content-between align-item-center">
      <h3>Service Resource</h3>
      <div class="gx-1">
        <button @click="openGroupModal" class="btn btn-primary waves-effect waves-float waves-light me-1">Add Group
        </button>
        <button @click="openResourceAddModal" class="btn btn-primary waves-effect waves-float waves-light">Add
          Resource
        </button>
      </div>
    </div>
    <div class="mt-2"></div>
    <Tab
        @onClickTab="onClickTab"
        :tabs="homeContent.resource_type"
        routeName="service-resources"
    />
    <hr>
    <TreeBrowser
        :style="{opacity: chartLoading ? .5 : 1}"
        v-for="(item, i) in serviceResourceList"
        :key="i"
        :node="item"
    />
    <p v-if="!serviceResourceList.length" class="text-center mt-5">No data</p>

    <GroupAddModal
        :showParent="showParent"
        :modalTitle="groupTitle"
        ref="groupModalRef"
        :type="homeContent.resource_type"
        :formData="groupFormData"
        :group="serviceResourceGroups"
        @onSubmit="handleGroupSubmit"
    />

    <ServiceResourceAddModal
        :showParent="showParent"
        :modalTitle="resourceTitle"
        ref="resourceModalRef"
        :type="homeContent.resource_type"
        :formData="resourceFormData"
        :specifications="homeContent.resource_specifications"
        :contacts="contactProfiles"
        :products="products"
        :userList="userList"
        :accountHeads="accountHeads"
        :group="serviceResourceGroups"
        @onSubmit="handleResourceSubmit"
    />
    <Loader v-if="loading"/>
    <div class="px-2"><Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/></div>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref} from "vue";
import handleHospital from '@/services/modules/hospital'
import handleCompanyContact from '@/services/modules/companyContact'
import TreeBrowser from '@/components/molecule/company/hospital/service-resources/ServiceResourceTree'
import Tab from '@/components/atom/Tab'
import Loader from '@/components/atom/LoaderComponent'
import GroupAddModal from '@/components/molecule/company/hospital/service-resources/GroupAddModal'
import ServiceResourceAddModal from '@/components/molecule/company/hospital/service-resources/ServiceResourceAddModal'
import {useRoute, useRouter} from "vue-router";
import handlePurchaseAndSales from "@/services/modules/purchase";
import handleInventory from "@/services/modules/inventory";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import Pagination from '@/components/atom/Pagination'
import {useStore} from "vuex";

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const router = useRouter()
const route = useRoute()
const companyId = computed(() => route.params.companyId);
const accountType = computed(() => route.params.accountType);
const emitter = inject('emitter');

const {fetchCompanyAllContacts} = handleCompanyContact();
const {fetchProductList} = handlePurchaseAndSales();
const {fetchAccountHeads, fetchHome} = handleInventory();
const { fetchContacts } = handleImportTracker();

const {
  chartLoading,
  fetchGroup,
  storeGroup,
  updateGroup,
  fetchServiceResources,
  storeServiceResource,
  updateServiceResource
} = handleHospital()

const loading = ref(false)
// Group
const groupTitle = ref('Add Group')
const showParent = ref(false)
const isGroupEdit = ref(false)
const groupModalRef = ref(null)
const groupFormData = ref({
  name: '',
  resource_type: null,
  status: 'active',
  company_id: '',
  head_group_id: null
})
// Resource
const resourceTitle = ref('Add Resource')
const isResourceEdit = ref(false)
const resourceModalRef = ref(null)
const resourceFormData = ref({
  contact_profile_id: '',
  service_user_id: null,
  name: '',
  service_code: '',
  short_description: '',
  full_description: '',
  resource_type: null,
  top_margin: 170,
  bottom_margin: 86,
  left_margin: 25,
  right_margin: 25,
  status: 'active',
  company_id: '',
  head_group_id: null,
  resource_descriptions: [
    {
      product_id: '',
      service_resource_id: '',
      sales_account_head_id: '',
      sales_price: 0,
      cost_of_sales_account_head_id: '',
      cost_of_sales_price: 0,
      service_specification: '',
      service_duration: '',
      is_opd_billing: false,
      is_repeat_weekly: 1,
      schedules: '',
    }
  ]
})

const store = useStore()
let offset = ref(20)
const contactProfiles = ref([])
const userList = ref([]);
const serviceResourceList = ref([])
const serviceResourceGroups = ref([])
const products = ref([])
const accountHeads = ref([])
const homeContent = ref({
  resource_type: {},
  resource_specifications: {}
})

const onClickTab = () => {
  getServiceResourceList()
  getGroups()
}

const openGroupModal = () => {
  clearGroupModal()
  groupTitle.value = `Add Group`
  isGroupEdit.value = false
  groupFormData.value.resource_type = accountType.value
  groupModalRef.value?.toggleModal()
}

const openResourceAddModal = () => {
  clearResourceModal()
  resourceTitle.value = `Add Resource`
  isResourceEdit.value = false
  showParent.value = true
  resourceFormData.value.resource_type = accountType.value
  resourceModalRef.value?.toggleModal()
}

emitter.on('onClickAddGroup', async (data) => {
  clearGroupModal()
  groupTitle.value = `Add Sub Group`
  isGroupEdit.value = false
  groupFormData.value.resource_type = accountType.value
  groupFormData.value.head_group_id = data.id
  groupModalRef.value?.toggleModal()
})

emitter.on('onUpdateGroup', async ({title, data}) => {
  const {id, name, resource_type, service_resource_id} = data;
  let formattedData = {
    id,
    name,
    resource_type,
    status: 'active',
    company_id: companyId.value,
    head_group_id: service_resource_id,
    _method: 'PUT'
  }
  groupTitle.value = title
  isGroupEdit.value = showParent.value = true
  groupFormData.value = formattedData
  groupModalRef.value?.toggleModal()
})

emitter.on('onClickAddResource', async (data) => {
  clearResourceModal()
  resourceTitle.value = `Add service resource`
  isResourceEdit.value = false
  resourceFormData.value.resource_type = accountType.value
  resourceFormData.value.head_group_id = data.id
  resourceModalRef.value?.toggleModal()
})

emitter.on('onUpdateResource', async ({title, data}) => {
  const {
    id,
    name,
    service_code,
    resource_type,
    contact_profile_id,
    service_resource_id,
    service_user_id,
    short_description,
    full_description,
    top_margin,
    bottom_margin,
    left_margin,
    right_margin,
    resource_descriptions
  } = data;
  let formattedData = {
    id,
    name,
    service_code,
    resource_type,
    contact_profile_id,
    service_user_id,
    short_description,
    full_description,
    top_margin,
    bottom_margin,
    left_margin,
    right_margin,
    resource_descriptions,
    status: 'active',
    company_id: companyId.value,
    head_group_id: service_resource_id,
    _method: 'PUT'
  }
  resourceTitle.value = title
  isResourceEdit.value = showParent.value = true
  resourceFormData.value = formattedData
  resourceModalRef.value?.toggleModal()
})

const handleGroupSubmit = async () => {
  try {
    loading.value = true
    groupFormData.value.company_id = companyId.value

    let res = isGroupEdit.value ? await updateGroup(groupFormData.value) : await storeGroup(groupFormData.value)
    if (!res.status) {
      showError(res.message)
    }
    if (res.status) {
      groupModalRef.value?.toggleModal()
      await getGroups()
      await getServiceResourceList()
      clearGroupModal()
      showSuccess(res.message)
      isGroupEdit.value = showParent.value = false
    }
  } catch (err) {
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false
  }
}

const handleResourceSubmit = async () => {
  try {
    loading.value = true
    resourceFormData.value.company_id = companyId.value
    // have to refactor
    let res = isResourceEdit.value ? await updateServiceResource(resourceFormData.value) : await storeServiceResource(resourceFormData.value)
    if (!res.status) {
      showError(res.message)
    }
    if (res.status) {
      resourceModalRef.value?.toggleModal()
      await getGroups()
      await getServiceResourceList()
      clearResourceModal()
      showSuccess(res.message)
      isResourceEdit.value = showParent.value = false
    }
  } catch (err) {
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false
  }
}

const getGroups = async () => {
  try {
    loading.value = true
    let res = await fetchGroup(companyId.value, accountType.value)
    if (!res.status) {
      serviceResourceGroups.value = []
    }
    if (res.status) {
      serviceResourceGroups.value = res.data
    }
  } catch (err) {
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false
  }
}

const page = computed(() => {
  return route.query.page
})
const getServiceResourceList = async () => {
  let query = `?company_id=${companyId.value}&resource_type=${accountType.value}`
  query += '&offset='+offset.value
  query += '&is_paginated=1'
  if(page.value) query += '&page='+page.value

  try {
    loading.value = true
    let res = await fetchServiceResources(query)
    if (!res.status) {
      store.commit("resetPagination")
      return serviceResourceList.value = []
    }
    serviceResourceList.value = res.data.data
    setPagination(res.data)
  } catch (err) {
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false
  }
}

const clearGroupModal = () => {
  groupFormData.value = {
    name: '',
    resource_type: null,
    status: 'active',
    company_id: '',
    head_group_id: null
  }
}

const clearResourceModal = () => {
  resourceFormData.value = {
    contact_profile_id: '',
    service_user_id: null,
    name: '',
    service_code: '',
    short_description: '',
    full_description: '',
    resource_type: null,
    top_margin: 170,
    bottom_margin: 86,
    left_margin: 25,
    right_margin: 25,
    status: 'active',
    company_id: '',
    head_group_id: null,
    resource_descriptions: [
      {
        product_id: '',
        service_resource_id: '',
        sales_account_head_id: '',
        sales_price: 0,
        cost_of_sales_account_head_id: '',
        cost_of_sales_price: 0,
        service_specification: '',
        service_duration: '',
        is_opd_billing: false,
        is_repeat_weekly: 1,
        schedules: '',
      }
    ]
  }
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  query += '&dropdown=' + true
  return query
}

function setPagination(data) {
  if(!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  getServiceResourceList()
}

onMounted(async () => {
  loading.value = true
  const homeRes = fetchHome();
  const contactProfileRes = fetchCompanyAllContacts(getQuery());
  let proQuery = getQuery()
  proQuery += '&product_type=services'
  const productsRes = fetchProductList(proQuery);
  const accountHeadRes = fetchAccountHeads(companyId.value);
  await Promise.all([
    homeRes.then(({data}) => {
      if (data) {
        const {resource_type, resource_specifications} = data;
        homeContent.value.resource_type = resource_type;
        homeContent.value.resource_specifications = resource_specifications;
      }
    }),
    contactProfileRes.then(({data}) => {
      if (data) contactProfiles.value = data
    }),
    fetchContacts(proQuery).then(({data}) => {
      if(data) userList.value = data;
    }),
    productsRes.then(({data}) => {
      if (data) products.value = data
    }),
    accountHeadRes.then(({data}) => {
      if (data) accountHeads.value = data
    }),
  ]).then(() => {
    loading.value = false;
  }).catch((err) => {
    loading.value = false
  });

  await getServiceResourceList()
  await getGroups()
})
</script>
