<template>
  <div>
    <div :class="`${node.type == 'group' ? 'node-hover' : ''}`">
      <div
          :style="{'margin-left': 25 * depth + 'px'}"
      >
        <div class="d-flex justify-content-between">
          <div class="node" @click="expanded = !expanded">
            <span v-if="hasChildren">{{ expanded ? '&#9660' : '&#9658' }}</span>
            <span v-if="node.type == 'group'">{{ node.name }}</span>
          </div>
          <div v-if="type == 'group'" class="d-flex">
            <div class="dropdown w-30">
              <button type="button" class="w-44 btn btn-sm dropdown-toggle py-0" data-bs-toggle="dropdown">
                <img src="/app-assets/images/icons/plus-solid.svg" alt="">
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <a @click.prevent="onClickAddGroupBtn" v-if="depth < 2" class="dropdown-item" href="#">
                  <span>Add sub Group</span>
                </a>
                <a @click.prevent="onClickAddResourceBtn(node)" class="dropdown-item" href="#">
                  <span>Add Resource</span>
                </a>
              </div>
            </div>
            <div class="w-20 z-999"></div>
            <div>
              <img
                  @click="emitGroupEdit"
                  width="13"
                  class="cursor-pointer"
                  src="/app-assets/images/icons/pencil-alt-solid.svg"
                  alt=""
              >
            </div>
          </div>
        </div>
        <div
            class="d-flex cursor-initial justify-content-between bg-black-light rounded-8 p-1 mb-1"
            v-if="node.type == 'endpoint'"
        >
          <p class="mb-0">{{ node.name }}</p>
          <div class="d-flex">
            <span class="badge badge-light-dark font-small-3">{{ node.service_general_status }}</span>
            <p class="mb-0 me-1">{{ node.total }}</p>
            <img @click="emitResourceEdit" width="13" class="cursor-pointer"
                 src="/app-assets/images/icons/pencil-alt-solid.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <hr class="mb-1 mt-0" v-if="type == 'group'">
    <template v-if="expanded">
      <TreeBrowser
          v-for="item in node.child"
          :key="item.name"
          :parent="node"
          :depth="depth + 1"
          :node="item"
      />
    </template>
  </div>
</template>

<script setup>
import {computed, inject, ref} from "vue";
import TreeBrowser from '@/components/molecule/company/hospital/service-resources/ServiceResourceTree'
import handleImportTracker from "@/services/modules/procurement/importTracker";
const emitter = inject('emitter');
const expanded = ref(true)

const props = defineProps({
  node: Object,
  depth: {
    type: Number,
    default: 0
  },
  parent: {
    default: []
  }
})

const hasChildren = computed(() => props.node.child.length)
const type = computed(() => props.node.type)
const parentL = computed(() => props.parent.child?.length ? props.parent.child?.length : 0)
const last = computed(() => props.parent.child ? props.parent.child[parentL.value - 1] : {})
const isLast = computed(() => {
  if (last.value.id) {
    return last.value.id === props.node.id
  }
  return false
})

const onClickAddGroupBtn = () => {
  emitter.emit('onClickAddGroup', props.node)
}

const emitGroupEdit = () => {
  emitter.emit('onUpdateGroup', {
    eventName: 'onUpdateGroup',
    title: 'Edit group',
    data: props.node
  })
}

const onClickAddResourceBtn = (data) => {
  emitter.emit('onClickAddResource', data)
}

const emitResourceEdit = () => {
  emitter.emit('onUpdateResource', {
    eventName: 'onUpdateLocation',
    title: 'Edit resource',
    data: props.node
  })
}
</script>

<style scoped>
.bg-black-light {
  background: #00000024;
}

.rounded-8 {
  border-radius: 8px;
}

.node {
  cursor: pointer;
}

.node-hover {
  padding: 10px 2px;
}

.node-hover:hover {
  background: #fffeec;
}

.cursor-initial {
  cursor: initial;
}

.cursor-pointer {
  cursor: pointer;
}

.w-30 {
  width: 30px;
}

.w-20 {
  width: 20px;
}

.z-999 {
  z-index: 999;
}

.w-44 {
  width: 44px;
}
</style>
